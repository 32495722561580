<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    {{ dollarFormatValue }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const dollarFormatValue = computed(() => (props.params?.value ? formatNumber(props.params.value, 2, '$') : ''));
</script>
